@import "./fonts.css";

@import "./normalize.css";

@import "./newslider.css";

@import "../../node_modules/air-datepicker/dist/css/datepicker.min.css";

@import "./section-6.css";

:root {
    --font: "Open Sans";
    --font-semi: "Open Sans Semi";
    --font-alt: Comfortaa;
    --font-size: 14px;
    --font-color: #473707;
    --background-color-1: #56ccf2;
    --background-color-2: #fafafa;
    --button-color: #f2c94c;
}

@custom-media --mobile-small (max-width: 29.999em);

@custom-media --mobile (min-width: 30em);

@custom-media --tablet (min-width: 48em);

@custom-media --desktop (min-width: 64em);

@custom-media --wide (min-width: 80em);

*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--font);
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    background-color: #fff;
    overflow-x: hidden;
    box-sizing: border-box;

    @media (--desktop) {
        padding-top: 40px;
    }
}

input[type = "number"]::-webkit-outer-spin-button,
input[type = "number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type = "number"] {
    -moz-appearance: textfield;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

button {
    cursor: pointer;
}

ul {
    list-style-type: none;
}

.nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & a {
        font-size: var(--font-size);
        line-height: 19px;
        color: #fff;
    }

    & .nav-buttons_item {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: none;

        & span {
            color: #fff;
            height: 24px;
        }

        &:first-child {
            margin: 0 16px;
        }
    }
}

.wrap {
    max-width: 1440px;
    margin: 0 auto;
    padding-right: 18px;
    padding-left: 16px;
    box-sizing: border-box;

    @media (--mobile) {
        padding-right: 30px;
        padding-left: 30px;
    }

    @media (--wide) {
        padding-right: 156px;
        padding-left: 156px;
    }
}

h2 {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;

    @media (--tablet) {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 48px;
    }

    &::after {
        content: "";
        display: block;
        width: 90px;
        height: 2px;
        background: #f8d97d;
        margin: 25px auto;
    }
}

.button {
    font-family: var(--font-semi);
    background: var(--button-color);
    border-radius: 80px;
    font-size: var(--font-size);
    line-height: 19px;
    color: var(--font-color);
    padding: 8px 30px;
    border: none;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        box-shadow: 0px 4px 14px rgba(242, 201, 76, .5);
        border-radius: 60px;
    }
}

.item-phone {
    font-size: 20px;
    line-height: 32px;
    color: #757575;

    @media (--tablet) {
        font-size: 15px;
    }

    & .js-phone::before {
        content: url("../assets/img/telephone.svg");
        top: 6px;
        position: relative;

        @media (--mobile) {
            top: 0;
        }

        @media (--tablet) {
            top: 6px;
        }
    }

    & a {
        text-decoration: none;
    }

    & span {
        font-size: 20px;
        line-height: 32px;
    }

    & span:first-child {
        color: #757575;
    }

    & span:last-child {
        color: var(--font-color);
    }
}

.bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 100;

    & .bar__wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        font-size: var(--font-size);
        padding-bottom: 17px;
        padding-top: 17px;
    }

    & .bar__logo {
        display: flex;

        & img {
            width: 143px;

            @media (--tablet) {
                width: 205px;
            }
        }
    }

    & .bar__button {
        display: none;

        @media (--mobile) {
            display: block;
            padding: 10px 15px;
        }
    }

    & .bar__text {
        display: none;

        @media (--desktop) {
            display: block;
            color: #757575;
            font-size: 12px;
            font-family: var(--font-semi);
            border-left: 1px solid #e0e0e0;
            height: 40px;
            padding-left: 40px;

            & p {
                max-width: 208px;
                line-height: 16px;
                align-items: stretch;
            }
        }
    }

    & .logo {
        margin-right: 40px;
        background-repeat: no-repeat; 
    }

    & .bar__side {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: var(--font-semi);

        & .bar__phone {
            font-family: var(--font-semi);

            @media (--mobile) {
                margin-right: 48px;
            }

            & .js-phone {
                display: flex;
            }

            & span {
                display: none;

                @media (--tablet) {
                    display: block;
                }
            }
        }

        & .bar__phone img {
            width: 24px;
            height: 24px;

            @media (--tablet) {
                padding-right: 54px;
            }
        }
    }

    @media (--tablet) {
        box-shadow: none;
    }
}

.intro {
    background-color: var(--background-color-1);

    & .intro__wrap {
        display: flex;
        padding-top: 82px;
        padding-bottom: 48px;

        @media (--tablet) {
            justify-content: space-around;
            padding-top: 100px;
            padding-bottom: 48px;
        }

        @media (--desktop) {
            padding-top: 64px;
            justify-content: space-between;
        }

        & h1 {
            position: relative;
            color: #fff;
            font-family: var(--font-alt);
            font-weight: bold;
            font-size: 24px;
            line-height: 25px;
            margin-top: 17px;
            z-index: 1;

            @media (--tablet) {
                position: static;
                font-size: 40px;
                line-height: 48px;
                letter-spacing: -1.5px;
            }
        }

        & .intro__button {
            font-family: var(--font-semi);
            font-size: 14px;
            line-height: 19px;
            color: var(--font-color);
            padding: 10px 20px;

            @media (--tablet) {
                margin-top: 16px;
                padding: 13px 34px;
                font-size: 16px;
                line-height: 22px;
            }
        }

        & .intro__text {
            color: #0c4f65;
            font-size: 12px;
            line-height: 14px;
            font-weight: normal;
            width: 82%;

            @media (--mobile) {
                font-family: var(--font-semi);
                font-size: 16px;
                width: 100%;
                padding-top: 20px;
                line-height: 22px;
            }

            @media (--tablet) {
                width: 100%;
                font-size: 24px;
                line-height: 32px;
                margin-top: -34px;
            }
        }

        & .intro__description {
            max-width: 340px;
        }
    }

    & .intro__img-mobile {
        display: block;
        position: relative;
        right: 100px;

        @media (--tablet) {
            position: static;
        }

        @media (--desktop) {
            display: none;
        }
    }

    & .intro__img {
        display: none;

        @media (--desktop) {
            display: block;
            position: relative;
            z-index: 4;
        }
    }
}

.services {
    padding: 48px 0;
    background-color: var(--background-color-2);

    @media (--tablet) {
        padding: 80px 0 48px;
    }

    @media (--desktop) {
        margin-top: -113px;
    }

    & .services__wrap {

        & .services__title {
            margin: 0 auto 48px;
            max-width: 722px;
        }

        & .services__description {

            @media (--tablet) {
                display: grid;
                column-gap: 45px;
                grid-template: 30% 37%/45% 45%;
            }

            @media (--desktop) {
                column-gap: 5px;
                grid-template: 25% 34%/53% 55%;
            }

            & .services__item {
                max-width: 533px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-self: flex-start;

                @media (--tablet) {
                    max-width: 100%;
                    margin-bottom: 32px;
                }

                @media (--desktop) {

                    max-width: 89%;
                }

                &.services__item:last-child {
                    align-self: self-start;
                }
            }

            & .serviсes__img {
                margin-right: 11px;
                align-self: flex-start;

                & img {
                    width: 48px;
                    height: 48px;
                }

                @media (--tablet) {
                    margin-right: 32px;

                    & img {
                        width: auto;
                        height: auto;
                    }
                }
            }

            & .services__text {
                display: flex;
                flex-direction: column;

                & h3 {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    color: #212121;
                    margin: 0;
                }

                & p {
                    font-weight: normal;
                    font-size: var(--font-size);
                    line-height: 24px;
                }
            }
        }
    }
}

.dissertations {
    padding: 48px 0;

    & .dissertations__item {
        margin-right: 3px;
    }

    .dissertations__item.jedli-slide:first-child {
        margin-left: 4px;
    }

    & .dissertations__list {
        display: flex;
        justify-content: space-between;
        padding: 0;

        & .dissertations__list-wrap {
            justify-content: space-around;
            max-width: 254px;
            height: 235px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, .05), 0px 4px 12px rgba(0, 0, 0, .15);
            border-radius: 24px;

            & h4 {
                font-size: 12px;
                margin: 0px;
                text-align: center;
                width: 70%;
                letter-spacing: 1.3px;

                @media (--tablet) {
                    font-size: 14px;
                }

                @media (--desktop) {
                    font-size: 16px;
                }
            }

            & p {
                color: #757575;
                font-size: var(--font-size);
                line-height: 20px;
            }

            & img {
                margin-bottom: 11px;
            }
        }

        & .list__wrap-monography {

            & img {
                margin-bottom: 22px;
            }

            & button {
                margin-top: 10px;
            }
        }
    }

    & .dissertations__nav {
        display: none;

        @media (--tablet) {
            display: flex;
            margin-top: 43px;

            & a {
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #757575;
            }

            & .dissertations__button {
                background-color: #abe5f8;

                &:hover {
                    background-color: #56ccf2;
                }
            }
        }
    }
}

.promotion {
    display: flex;
    justify-content: center;
    background-color: var(--background-color-1);
    padding-top: 24px;


    @media (--desktop) {
        justify-content: space-between;
    }

    & img {
        display: none;

        @media (--desktop) {
            display: block;
        }
    }

    & h3 {
        width: 74%;
        font-family: var(--font-alt);
        font-weight: bold;
        font-size: 18px;
        color: #fff;
        line-height: 28px;
        text-align: center;

        @media (--tablet) {
            width: 100%;
            font-size: 30px;
            line-height: 32px;
        }
    }

    & .propmotion__desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 649px;
        justify-content: center;
        margin-bottom: 48px;

        @media (--tablet) {
            margin-bottom: 66px;
        }
    }

    & .propmotion__button {
        padding: 10px 20px;

        @media (--tablet) {
            padding: 13px 59px;
            font-size: 16px;
            letter-spacing: .4px;
        }
    }
}

.order {
    padding: 48px 0;

    @media (--tablet) {
        padding: 47px 0 84px;
    }


    & .order__wrap {
        display: flex;
        flex-direction: column;

        & h2 {

            @media (--desktop) {
                margin-bottom: 21px;
            }
        }

        & .order__list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 0;

            @media (--mobile) {
                margin: 0 auto;
                width: 80%;
                justify-content: space-evenly;
            }

            @media (--tablet) {
                width: 100%;
                flex-wrap: nowrap;
                justify-content: space-around;
            }

            @media (--desktop) {
                justify-content: space-evenly;
            }

            & .order__item {
                max-width: 140px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 24px;

                @media (--tablet) {
                    margin-bottom: 38px;
                    max-width: 200px;
                }


                & .order__text {
                    font-family: var(--font-semi);
                    width: 140px;
                    font-size: 12px;
                    text-align: center;
                    line-height: 16px;

                    @media (--tablet) {
                        width: 100%;
                        line-height: 20px;
                        letter-spacing: .5px;
                    }

                    @media (--desktop) {
                        font-size: 14px;
                    }

                    @media (--wide) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    & .order__button {
        font-size: 14px;
        padding: 13px 20px;
        margin: 0 auto;

        @media (--tablet) {
            font-size: 16px;
            padding: 13px 30px;
        }
    }
}

.reviews {
    background-color: var(--background-color-1);
    color: #fff;
    padding: 48px 0;

    @media (--tablet) {
        padding: 87px 0 84px;
    }

    & .reviews__title {
        margin: 0;
    }

    & .rating__star {
        background-image: url("../assets/img/star.svg");
        width: 16px;
        height: 16px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: 50%;

        @media (--tablet) {
            width: 21px;
            height: 24px;
            margin: 0 2px;
        }
    }

    & .reviews__rating {
        font-size: 18px;
        line-height: 24px;
        text-align: center;

        @media (--tablet) {
            text-align: center;
            margin-top: -6px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & .rating__title {
            font-size: 14px;
            font-weight: normal;
            line-height: 24px;

            @media (--tablet) {
                font-size: 18px;
                font-weight: bold;
                margin-right: 9px;
                display: block;
                letter-spacing: 1px;
            }
        }

        & .rating__disc {
            font-size: 14px;
            font-weight: normal;

            @media (--tablet) {
                font-size: 18px;
                font-weight: 400;
                margin-left: 12px;
            }
        }
    }

    & .reviews__button-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 32px auto 24px;

        @media (--tablet) {
            justify-content: center;
        }

        @media (--wide) {
            margin: 56px auto 0;
            width: 100%;
        }

        & .reviews__button {
            background-color: var(--background-color-1);
            color: #fff;
            border: 2px solid #fff;
            padding: 8px 20px;
            white-space: nowrap;
            margin-right: 10px;
            letter-spacing: .5px;

            &:hover {
                background-color: #fff;
                color: var(--background-color-1);
                box-shadow: none;
            }
        }
    }

    & .reviews__text {
        display: flex;
        justify-content: space-between;

        @media (--desktop) {
            padding: 23px 0 0px;
        }

        & .reviews-item {
            min-height: 333px;
            min-width: 235px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, .1);
            border-radius: 20px;
            padding: 24px 14px;
            margin: 0 16px 0 0;
            overflow: hidden;

            @media (--desktop) {
                padding: 24px 24px 32px;
                margin: 0 24px 0 0;
            }

            & .reviews-item__name {
                font-family: var(--font-alt);
                font-weight: normal;
                font-size: 18px;
                line-height: 20px;
                color: var(--background-color-1);

                @media (--tablet) {
                    font-size: 20px;
                    line-height: 22px;
                    font-weight: bold;
                }

                @media (--desktop) {
                    font-size: 24px;
                    line-height: 25px;
                }

                &::before {
                    position: relative;
                    content: url("../assets/img/quotes.svg");
                    width: 18px;
                    height: 16px;
                    right: 20%;

                    @media (--desktop) {
                        height: auto;
                        width: auto;
                        right: 55px;
                    }

                    @media (--wide) {
                        right: 22px;
                    }
                }
            }

            & .reviews-item__date {
                font-size: var(--font-size);
                line-height: 19px;
                text-align: center;
                color: #757575;
                margin: 5px 0 10px;
            }

            & .reviews-item__article {
                display: block;
                position: relative;
                max-height: 140px;
                overflow: hidden;
                font-weight: normal;
                font-size: var(--font-size);
                line-height: 24px;
                color: #212121;
                font-style: italic;
                transition: height .25s ease;

                @media (--tablet) {
                    max-height: 200px;
                    margin-bottom: 16px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    height: 100px;
                    width: 100%;
                    top: 30%;
                    right: 0;
                    background: linear-gradient(360deg, #ffff 0%, rgba(255, 255, 255, 0) 100%);
                    

                    @media (--tablet) {
                        height: 166px;
                    }

                    @media (--desktop) {
                        height: 184px;
                        top: 8%;
                    }
                }

                @media (--mobile) {
                    font-style: normal;
                }
            }

            & .reviews-item__article-active {
                position: relative;
                font-weight: normal;
                font-size: var(--font-size);
                line-height: 24px;
                color: #212121;
                font-style: italic;
                transition: height .25s ease;

                @media (--tablet) {
                    max-height: fit-content;
                    margin-bottom: 16px;
                }

                @media (--mobile) {
                    font-style: normal;
                }
            }

            & .reviews-item__action {
                cursor: pointer;
                display: block;
                font-weight: normal;
                font-size: var(--font-size);
                line-height: 16px;
                color: var(--button-color);

                &::after {
                    position: relative;
                    content: url("../assets/img/arrow-down.svg");
                    left: 3px;
                    bottom: 2px;
                }
            }

            & .reviews-item__action-hide {
                cursor: pointer;
                display: block;
                font-weight: normal;
                font-size: var(--font-size);
                line-height: 16px;
                color: var(--button-color);

                &::after {
                    position: relative;
                    content: url("../assets/img/arrow-up.svg");
                    left: 3px;
                    bottom: 2px;
                }
            }

        }
    }

    & .reviews__nav {
        display: none;

        @media (--tablet) {
            display: flex;
            margin-top: 58px;
        }
    }

    & .nav-buttons_item {
        background: #fff;
        opacity: .5;

        &:hover {
            opacity: 1;
            background: #fff;
        }

        & span {
            color: #56ccf2;
        }
    }
}

.advice {
    background: var(--background-color-2);
    padding: 48px 0;

    @media (--tablet) {
        padding: 42px 0 94px;
    }

    & img {
        width: 100%;

        @media (--tablet) {
            width: 90%;
        }

        @media (--desktop) {
            width: 100%;
        }
    }

    & .advice__content {
        display: flex;
        flex-direction: column;

        & .advice__block {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;

            @media (--tablet) {
                flex-direction: row;

                & .img-left {
                    margin-right: 20px;
                }

                & .img-right {
                    margin-left: 20px;
                    align-self: flex-end;
                }
            }

            & .advice__text_wrap {
                max-width: 456px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                @media (--tablet) {
                    max-width: 342px;
                }

                @media (--desktop) {
                    max-width: 456px;
                    align-self: flex-end;
                }
            }

            & .advice__text {
                font-size: 14px;
                line-height: 24px;
                color: #212121;
                font-weight: normal;
                padding-top: 15px;

                @media (--desktop) {
                    font-size: 16px;
                    line-height: 28px;
                }
            }

            &:last-child {

                @media (--tablet) {
                    margin-top: 25px;
                    text-align: end;
                }

                & .advice__img {
                    order: 0;

                    @media (--tablet) {
                        order: 1;
                    }
                }
            }
        }

        & .block-down {
            margin-top: 41px;
        }
    }

    & .advice__button {
        padding: 10px 28px;
        font-size: 14px;
        line-height: 19px;
        font-family: var(--font-semi);
        margin: 0 auto;

        @media (--tablet) {
            margin: 0;
            padding: 12px 18px;
        }

        @media (--desktop) {
            font-size: 16px;
            line-height: 22px;
            padding: 13px 30px;

        }
    }
}

.faq {
    display: block;
    text-align: center;
    padding: 48px 0 222px;
    line-height: 20px;

    @media(--tablet) {
        padding: 116px 0 171px;
    }

    & .faq__title {
        font-size: 28px;
        margin-bottom: 10px;

        @media(--tablet) {
            font-size: 36px;
            margin-bottom: 30px;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;

        @media(--tablet) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media(--tablet) {
            justify-content: space-around;
        }


    }

    &__wrap {
        order: 1;

        @media(--tablet) {
            order: 0;
            width: 40%;
        }
    }
}

.faq-card__title {
    position: relative;
    padding: 14px 60px 14px 30px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    color: #212121;

    &::before, &::after {
        content: "";
        position: absolute;
        display: block;
        opacity: 1;
        transition: opacity .3s;
        background-repeat: no-repeat;
    }

    &::before {
        width: 32px;
        height: 32px;
        top: 50%;
        right: 21px;
        margin-top: -16px;
        background-color: #56ccf2;
        border-radius: 50%;
    }

    &::after {
        width: 14px;
        height: 15px;
        top: 50%;
        right: 30px;
        margin-top: -3px;
        background-image: svg-load("../assets/img/ic.svg");
    }
}

.faq-card__body {
    display: none;
    padding: 0 30px 20px;
    font-size: 14px;
}

.faq-card {
    font-size: 18px;
    text-align: left;
    color: #484c52;
    background: #eefafe;
    border-radius: 20px;

    &__hide {
        display: none;
        margin-top: 20px;
    }

    & + .faq-card {
        margin-top: 12px;
    }

    & p:first-of-type {
        margin-top: 0;
    }

    & a {
        color: #22a9d3;
        text-decoration: underline;
    }

    &.open {

        & .faq-card__title {

            &::after {
                margin-top: -12px;
                transform: rotate(180deg);
            }
        }
    }

    &__quest {
        padding-top: 10px;
        margin-bottom: 40px;
        text-align: center;

        &-title {
            position: relative;
            top: 8px;
            background: #fff;
            padding: 0 10px;
            color: var(--background-color-1);
            font-size: 14px;
            cursor: pointer;
            transition: all .3s;

            @media (--tablet) {
                color: #757575;
            }

            &:hover {
                color: #484c52;
            }
        }
    }
}

.faq__help {
    text-align: left;

    @media(--wide) {
        padding-top: 47px;
        position: relative;
        right: 75px;

    }

    .faq__title {
        font-size: 18px;
        line-height: 28px;
        border: none;

        @media (--tablet) {
            width: 69%;
            text-align: left;
            font-size: 24px;
            line-height: 25px;
            margin-bottom: 11px;
        }

        &::after {
            content: none;
        }
    }

    &__disc {
        font-size: 16px;
        line-height: 24px;
        color: #000;
        opacity: .5;
        margin-bottom: 25px;
    }

    &__consult {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        color: #828282;
        font-weight: normal;

        @media (--tablet) {
            font-size: 16px;
            line-height: 27px;
        }

        span {
            color: #212121;
            cursor: pointer;
            font-weight: bold;
        }
    }

    &__phone {
        font-size: 16px;
        line-height: 27px;
        color: #828282;
        font-weight: normal;
        margin-bottom: 30px;

        a {
            font-weight: bold;
            font-size: 16px;
            line-height: 27px;
            color: #484c52;
        }

        span {
            display: block;
            padding-top: 10px;
            color: #99a0a9;
            font-family: Inter;
            font-weight: normal;
            font-size: 16px;
        }
    }

    & .button {
        display: none;
        padding: 13px 30px;
        font-family: var(--font-semi);
        font-size: 16px;
        color: #473707;

        @media (--tablet) {
            display: block;
        }
    }
}

.section-6 {
    max-height: 480px;
    background: var(--background-color-1);
    padding-bottom: 375px;

    @media (--tablet) {
        padding-bottom: 160px;
    }

    & .section-6__title {
        width: 80%;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #333;
        margin: 0 auto 48px;

        &::after {
            content: none;
        }

        @media (--tablet) {
            padding-bottom: 0;
            width: 95%;
            font-size: 30px;
            line-height: 32px;
        }

        @media (--desktop) {
            margin-bottom: 40px;
        }
    }

    & .section-6__wrap {
        max-width: 90%;
        position: relative;
        bottom: 194px;
        padding: 50px 0;
        background: #fff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, .15);
        border-radius: 40px;
        font-weight: 400;

        @media (--tablet) {
            max-width: 744px;
            padding: 32px 80px 40px;
            bottom: 127px;
        }
    }
}

.form {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (--desktop) {
        max-width: 700px;
    }

    & .field__submit {
        display: flex;
        flex-direction: column;

        & .button {
            font-family: var(--font-semi);
            font-size: 16px;
            line-height: 22px;
            align-self: center;
            padding: 13px 49px;
            margin-top: 6px;
        }
    }

    & .field {
        position: relative;
        display: flex;
        flex: 1 1 100%;
        margin: 0;
        padding: 6px 15px;
        flex-wrap: wrap;

        & ::hover {
            cursor: text;
            border: 1px solid #56ccf2;
            border-radius: 40px;
        }

        & .input-flag {
            padding-left: 50px;
        }

        @media (--tablet) {
            flex: 1 1 40%;
            padding: 10px 15px;
        }

        & .field {
            padding: 0;
            width: 100%;

            @media (--tablet) {
                width: 50%;
                padding: 0 7.5px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                margin-top: 12px;

                @media (--tablet) {
                    margin-top: 0;
                }

                &.error::after {
                    right: 22px;
                }

                /* &.success::after {
                    right: 20px;
                } */
            }
        }

        & .field__icon {
            content: "";
            position: absolute;
            top: 50%;
            left: 35px;
            display: block;
            width: 16px;
            height: 20px;
            margin-top: -6px;
            background-repeat: no-repeat;
            z-index: 1;

            &.field__icon_name {
                margin-top: -5px;
            }

            &.field__icon_select {
                left: auto;
                right: 35px;
                margin-top: -2px;
                background-image: svg-load("../assets/img/arrow-down.svg");
            }
        }
    }

    & .field_fluid {

        @media (--tablet) {
            flex: 1 1 100%;
            padding: 10px 15px;
        }
    }

    & select[name = "WorkTypeAuto"] {
        appearance: none;
        background: url("../assets/img/arrow-down.svg") no-repeat;
        background-position: 90% 53%;
        cursor: pointer;
        height: 52px;

        & option {
            word-wrap: break-word;
            max-width: 100px;
        }
    }
    

    & .input {
        width: 100%;
        height: 48px;
        padding-left: 20px;
        font-size: 14px;
        color: ##828282;
        background-color: #fff;
        border: 0;
        border-radius: 40px;
        transition: box-shadow .3s;
        border-radius: 40px;
        border: 1px solid #e0e0e0;

        
        &:focus {
            cursor: text;
            outline: none;
            border: 1px solid var(--button-color);
        }

        &:hover {
            border: 1px solid var(--background-color-1);
        }

        &.input_invert {
            border: solid 1px #e0e0e0;
        }
    }

    & .form__wrapper {
        display: flex;
        flex-direction: column;

        @media (--tablet) {
            flex-direction: row;
        }

        
        & label {
            white-space: nowrap;
            color: #828282;
            line-height: 36px;
            font-weight: 400;
            font-size: 14px;

            @media (--desktop) {
                font-size: 16px;
            }

            & span:first-child {
                color: #eb5757;
            }
        }
    }
}

.error {

    &.field .field__icon.field__icon_select,
    &.field .field__icon.field__icon_date {
        display: none;
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 37px;
        display: block;
        width: 18px;
        height: 20px;
        margin-top: -9px;
        background-image: svg-load("../assets/img/error.svg");
        background-repeat: no-repeat;
        z-index: 1;
    }
}

.section-8 {
    overflow: hidden;
    background-color: #f2f2f2;

    & .section-8__wrap {
        overflow-x: auto;
    }
}


.payways {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1024px;
    padding: 15px 0;

    @media (--desktop) {
        padding: 23px 0;
    }

    & .payways__item {
        display: block;
        background-repeat: no-repeat;
        opacity: .5;
        transition: opacity .3s;

        &:hover {
            opacity: 1;
        }
    }

    & .payways__item_text {
        display: none;
        opacity: 1;
        color: #757575;
        font-family: var(--font-semi);

        @media (--desktop) {
            display: block;
        }
    }

    & .payways__item_qiwi {
        width: 75px;
        height: 34px;
        background-image: svg-load("../assets/img/qiwi.svg");
        background-size: 75px 34px;
    }

    & .payways__item_visa {
        width: 100px;
        height: 32px;
        background-image: svg-load("../assets/img/cards.svg");
        background-size: 100px 32px;
    }

    & .payways__item_yandex {
        width: 122px;
        height: 24px;
        background-image: svg-load("../assets/img/yandex.svg");
        background-size: 122px 24px;
    }

    & .payways__item_euroset {
        width: 108px;
        height: 18px;
        background-image: svg-load("../assets/img/euroset.svg");
        background-size: 108px 18px;
    }

    & .payways__item_svyznoy {
        width: 111px;
        height: 24px;
        background-image: svg-load("../assets/img/svyznoy.svg");
        background-size: 111px 24px;
    }

    & .payways__item_tinkoff {
        width: 124px;
        height: 34px;
        background-image: svg-load("../assets/img/tinkoff.svg");
        background-size: 124px 34px;
    }

    & .payways__item_webmoney {
        width: 130px;
        height: 32px;
        background-image: svg-load("../assets/img/webmoney.svg");
        background-size: 130px 32px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    padding: 48px 0px;

    @media (--desktop) {
        padding: 28px 20px;
    }

    & .contacts__item {
        align-self: baseline;
        margin-bottom: 24px;

        @media (--mobile) {
            align-self: auto;
            margin-bottom: 0;
        }
    }

    .contacts__item_socials {
        margin: 0 auto;

        & a:hover {
            box-shadow: 0 0 10px rgba(0,0,0,.5);
        }

        & a {
            transition: all .2s;
        }

        @media (--mobile) {
            margin: 0;
        }
    }

    & .contacts__item-icon {
        align-self: center;
    }

    @media (--mobile) {
        row-gap: 10px;
        display: grid;
        grid-template: 50% 50% / 65% 50%;
    }

    @media (--desktop) {
        display: flex;
        padding: 28px 0 20px;
    }

    @media (--tablet) {
        flex-direction: row;
        justify-content: space-between;
    }

    & .contacts__item_email {
        display: flex;

        & span {
            color: #212121;
        }

        & .contacts__item-icon {
            margin-right: 16px;
            width: 21px;
            height: 24px;
            background-image: svg-load("../assets/img/mail.svg");
            background-repeat: no-repeat;
        }

        &::active {
            color: #212121;
        }
    }

    & .contacts__item_address {
        max-width: 210px;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #757575;
        display: flex;
        justify-content: flex-end;

        @media (--tablet) {
            max-width: 365px;
            position: static;
        }

        @media (--desktop) {
            max-width: 380px;
        }

        & .contacts__item-icon {
            margin-right: 21px;
            min-width: 18px;
            height: 24px;
            background-image: svg-load("../assets/img/map.svg");
            background-repeat: no-repeat;

            @media (--desktop) {
                margin-right: 16px;
            }
        }
    }

    & .js-phone > span {
        color: #212121;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    &__item_socials {
        display: flex;
        justify-content: space-between;
        width: 110px;
        margin-top: 32px;

        @media (--tablet) {
            margin-top: 0px;
        }

        & .item_socials__telegram {
            width: 32px;
            height: 32px;
            background-image: svg-load("../assets/img/telegram.svg");
            border-radius: 50%;
        }

        & .item_socials__viber {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            background-image: svg-load("../assets/img/viber.svg");
        }

        & .item_socials__whatsapp {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            background-image: svg-load("../assets/img/whatsapp.svg");
        }
    }
}

.quantity {
    margin: 0;
    position: relative;
    display: flex;
    padding: 6px 15px;

    @media (--mobile) {
        padding: 10px 15px;
    }

    @media (--tablet) {
        padding: 10px 0px;
    }

    
    & .quantity__input {
        display: flex;
        flex-flow: column;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        & button {
            width: 22px;
            height: 22px;
            border: none;
            border-radius: 50%;
            background: rgba(242,201,76, .1);
            padding: 0;
            position: absolute;

            &:hover::before {
                transition: top 1s ease-out .5s;
                background: #fff;
                border-radius: 50%;
            }

        }

        & .quantity__input-minus {
            left: 25px;

            &::before {
                content: "-";
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                font-weight: 600;
                color: var(--button-color);
                width: 22px;
                height: 22px;
                font-size: 20px;
            }

            &:hover::before {
                transition: top 1s ease-out 0.s;
                color: var(--button-color);
            }
        }

        .quantity__input-plus {
            right: 25px;

            &::before {
                content: "+";
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                font-weight: 600;
                color: var(--button-color);
                width: 22px;
                height: 22px;
                font-size: 20px;
            }

            &:hover::before {
                color: var(--button-color);
            }
        }

        & input {
            max-width: 90px;
            background: none;

            @media (--tablet) {
                max-width: 124px;
            }
        }
    }

    & .input {
        border-radius: 40px;
        border: 1px solid #e0e0e0;
        padding: 0 30px;
        text-align: center;

        @media (--mobile) {
            padding: 0 100px;
        }

        @media (--tablet) {
            padding: 0 8px;
        }
    }
}

.mainform__btnBlock-form {
    display: flex;
    cursor: pointer;
    border: none;
    flex-direction: column;
    align-self: center;

    @media (--tablet) {
        align-self: flex-end;
    }

    & .label__check {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 1.5rem;
        color: #828282;
        font-size: 1.2rem;
        cursor: pointer;
        align-self: center;
        margin-top: 30px;
        margin-left: 5px;

        & input {
            display: none;

            &:checked + i {
                background: var(--button-color);
            }

            &:checked + i + span {
                color: #29316b;
            }

            &:checked + i::after {
                transform: translateX(16px);
            }
        }

        & span {
            font-size: .875rem;
            line-height: 1.5rem;
            color: #828282;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
            white-space: nowrap;
        }

        & i {
            display: inline-block;
            width: 38px;
            height: 20px;
            border-radius: 20px;
            vertical-align: middle;
            transition: .25s .09s;
            position: relative;
            background: #a9acb8;

            &::after {
                content: " ";
                display: block;
                width: 16px;
                height: 16px;
                top: 2px;
                left: 3px;
                border-radius: 50%;
                background: #fff;
                position: absolute;
                box-shadow: 1px 2px 4px 0 rgba(#000, .4);
                transition: .15s;
            }
        }

        &.active {

            & i {
                background: var(--button-color);

                &::after {
                    transform: translateX(16px);
                }
            }

            & span {
                color: #29316b;
            }
        }

        @media (--mobile) {
            flex-direction: row;
        }

        @media (--tablet) {
            margin-left: 6px;
            flex-direction: row;
            align-items: unset;
            margin-top: 30px;
            width: 112px;
            justify-content: space-between;
        }
    }

    @media (--mobile) {
        align-self: center;
    }
    
    @media (--tablet) {
        flex-direction: row;
        align-self: center;
    }
}

.authorform__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    color: #828282;
    font-size: .75rem;
    line-height: normal;
    user-select: none;
    padding-left: 30px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;

    @media (--tablet) {
        margin-left: 0px;
    }

    & a {
        color: #828282;
        transition: color .1s;

        &:hover {
            opacity: .5;
        }
    }

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &::checked ~ span::after {
            display: block;
        }
    }

    & .authorform__checkbox-check {
        position: absolute;
        top: 2px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #f2c94c;
        border-radius: 4px;
    }

    & .authorform__checkbox-check.checked {

        &::after {
            content: "";
            position: absolute;
            display: block;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: 2px solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}

.top-link {
    display: none;

    @media (--tablet) {
        display: inline-flex;
    }
    border: none;
    transition: all .25s ease-in-out;
    position: fixed;
    bottom: 0;
    right: 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin: 0 3em 3em 0;
    border-radius: 50%;
    padding: .25em;
    width: 48px;
    height: 48px;
    background-color: var(--button-color);

    &:hover {
        box-shadow: 0px 4px 14px rgba(242, 201, 76, .5);
        border-radius: 60px;
    }

    & .show {
        visibility: visible;
        opacity: 1;
    }

    &.hide {
        visibility: hidden;
        opacity: 0;
    }

    & img {
        width: 21px;
        height: 26px;
        color: #fff;
    }
}

.owl-loaded.owl-drag {
    max-width: 1440px;
    overflow-x: hidden;
}

.owl-stage-outer {
    padding: 10px;
}

.popup-layer {
    display: none;
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    table-layout: fixed;
    z-index: 999;
    background: rgba(0, 0, 0, 0);
    transition: background .3s;
}

.popup-layer.active {
    display: table;
    background: rgba(0, 0, 0, .35);
}

.popup-wrap {
    width: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding-right: 15px;
    padding-left: 15px;
}

.popup {
    display: none;
    position: relative;
    max-width: 640px;
    margin: 0 auto;
    background: #fff;
    padding: 10px 15px;
    opacity: 0;
    border-radius: 20px;

    @media(--desktop) {
        padding: 20px;
    }
}

.popup-box {
    font-size: 16px;
    color: #484c52;

    @media(--desktop) {
        font-size: 18px;
    }
}

.popup-box_success {
    padding-top: 120px;
    background-image: url("../assets/img/popup-success.svg");
    background-position: center 20px;
    background-repeat: no-repeat;
}

.popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 48px;
    height: 48px;
    background: rgb(235,87,87,.1);
    z-index: 1;
    border-radius: 50%;
    cursor: pointer;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 24px;
        left: 15px;
        width: 20px;
        height: 2px;
        background: rgb(235,87,87,.5);
        transition: background .3s;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }

    &:hover {
        background: rgb(235,87,87,.3);

        &::before,
        &::after {
            background: rgb(235,87,87,.8);
        }
    }
}

.popup-title {
    font-family: Lato, sans-serif;
    font-size: 24px;
    color: #22a9d3;

    @media(--desktop) {
        font-size: 30px;
    }
}

.popup-button {
    width: 100%;
    margin: 0 auto;

    @media(--desktop) {
        width: auto;
    }
}

.loading {

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        margin: -9px 0 0 -9px;
        border-radius: 50%;
    }

    &::before {
        border: 2px solid rgba(0,0,0,.15);
    }

    &::after {
        border-width: 2px;
        border-style: solid;
        border-color: #fff transparent transparent;
        box-shadow: 0 0 0 1px transparent;
        animation: button-spin .6s linear;
        animation-iteration-count: infinite;
    }
}

