@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/newFonts/Open-Sans/opensans-regular-webfont.woff2") format("woff2"),
        url("../assets/fonts/newFonts/Open-Sans/opensans-regular-webfont.woff") format("woff"),
        url("../assets/fonts/newFonts/Open-Sans/opensans-regular-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans Semi";
    src: url("../assets/fonts/newFonts/Open-Sans/opensans-semibold-webfont.woff2") format("woff2"),
        url("../assets/fonts/newFonts/Open-Sans/opensans-semibold-webfont.woff") format("woff"),
        url("../assets/fonts/newFonts/Open-Sans/opensans-semibold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Comfortaa; 
    src: url("../assets/fonts/newFonts/Comfortaa/Comfortaa-Regular.woff2") format("woff2"),
        url("../assets/fonts/newFonts/Comfortaa/Comfortaa-Regular.woff") format("woff"),
        url("../assets/fonts/newFonts/Comfortaa/Comfortaa-Regular.ttf") format("truetype"),
        url("../assets/fonts/newFonts/Comfortaa/Comfortaa-Regular.svg#Comfortaa-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}
