.newslider .item {
    width: 243px;
    border: 1px solid;
    height: 273px;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul.newslider.owl.carousel {
    display: flex;
    max-width: 1280px;
    overflow: hidden;
    width: 100%;
    justify-content: flex-start;
    box-sizing: border-box;
}

.newslider li {
    box-sizing: border-box;
}

.owl-stage {
    display: flex;
    justify-content: center;
}