.autocomplete-suggestions {
    margin-top: 10px;
    overflow-y: auto;
    font-family: var(--main-font);
    font-size: .875rem;
    color: #828282;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 30px rgba(0,0,0,.15);
}

.autocomplete-suggestion {
    padding: 8px 20px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

.autocomplete-suggestion:hover {
    background: #def2f8;
}


.form .input {
    width: 100%;
    height: 48px;
    padding-left: 20px;
    font-size: 1rem;
    color: #484c52;
    color: var(--main-font-color);
    background-color: #fff;
    border: 0;
    border-radius: 40px;
    transition: box-shadow .3s;
    cursor: pointer;
}

.iti__selected-flag {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}

.success::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 35px;
    z-index: 1;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -6px;
    background-image: url("../assets/img/success.svg");
    background-repeat: no-repeat;
}

.success-alt::after {
    content: "";
    position: absolute;
    top: 66%;
    right: 35px;
    z-index: 1;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -6px;
    background-image: url("../assets/img/success.svg");
    background-repeat: no-repeat;
}

.form .field .field__icon.field__icon_date {
    right: 32px;
    left: auto;
    margin-top: 8px;
    background-image: url("../assets/img/calendar.svg");
}

.js-datepicker {
    color: #e0e0e0;
}

.iti {
    width: 100%;
}